import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import KPIContainer from './KPIContainer';
import KPI from './KPI';
import ChatbotFloater from './ChatbotFloater';
import Layout from "./Layout";

const Dashboard = () => {
  const [isKPIBarCollapsed, setIsKPIBarCollapsed] = useState(false);

  const kpiData = {
    sales: '+15%',
    inventory: 'Optimal',
    customerSatisfaction: '4.8/5',
    revenueForecast: '+12%',
  };

  const toggleKPIBar = () => setIsKPIBarCollapsed(!isKPIBarCollapsed);

  return (
      <Layout>
        <div className="flex min-h-screen bg-gray-100">
          <div className="flex-grow transition-all duration-300">
            {/* KPI Bar */}
            <div className="bg-gray-800 shadow-md p-4 rounded-lg">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-white">KPIs</h2>
                <button
                    onClick={toggleKPIBar}
                    className="text-white focus:outline-none"
                >
                  {isKPIBarCollapsed ? <FaChevronDown/> : <FaChevronUp/>}
                </button>
              </div>
              {!isKPIBarCollapsed && (
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    <KPI id={1} name="Sales"/>
                    <KPI id={2} name="Inventory"/>
                    <KPI id={3} name="Revenue"/>
                    <KPI id={4} name="Customer Satisfaction"/>
                  </div>
              )}
            </div>

            {/* Content */}
            <main className="mt-5 flex-grow">
              <section id="kpi-container" className="bg-white rounded-lg shadow-md p-6 h-full">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">KPI Analysis</h2>
                <KPIContainer/>
                <ChatbotFloater kpiData={kpiData}/>
              </section>
            </main>
          </div>
        </div>
      </Layout>
  );
};

export default Dashboard;