import React, { useState } from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import { motion, AnimatePresence } from 'framer-motion';
import { FaSmile, FaSyncAlt, FaUsers, FaArrowCircleRight } from 'react-icons/fa';
import Layout from './Layout';
import Modal from './Modal';

const CustomerInsights = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const satisfactionData = {
    labels: ['Very Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Very Dissatisfied'],
    datasets: [
      {
        label: 'Customer Satisfaction',
        data: [45, 30, 15, 7, 3],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(255, 99, 132, 0.6)',
          'rgba(153, 102, 255, 0.6)',
        ],
      },
    ],
  };

  const churnData = {
    labels: ['18-25', '26-35', '36-45', '46-55', '55+'],
    datasets: [
      {
        label: 'Churn Rate by Age Group',
        data: [12, 8, 15, 10, 5],
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      },
    ],
  };

  const customerSatisfaction = 85;
  const churnRate = 10;
  const lifetimeValue = 480;

  const renderRetentionCampaign = () => (
    <div>
      <p className="mb-4 text-gray-300">Target specific customer segments with personalized offers:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-300">Select Age Group</label>
          <select className="w-full bg-gray-700 border-gray-600 text-white rounded-md p-2">
            <option>36-45</option>
            <option>26-35</option>
            <option>18-25</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-300">Offer Type</label>
          <select className="w-full bg-gray-700 border-gray-600 text-white rounded-md p-2">
            <option>Discount</option>
            <option>Free Trial</option>
            <option>Loyalty Points</option>
          </select>
        </div>
        <motion.button 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="button" 
          className="bg-gradient-to-r from-red-600 to-red-800 text-white px-6 py-2 rounded-full hover:from-red-700 hover:to-red-900 transition-all duration-300 shadow-lg"
        >
          Launch Campaign
        </motion.button>
      </form>
    </div>
  );

  const renderFeedbackLoop = () => (
    <div>
      <p className="mb-4 text-gray-300">Send a survey to gather feedback from neutral and dissatisfied customers:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-300">Survey Type</label>
          <select className="w-full bg-gray-700 border-gray-600 text-white rounded-md p-2">
            <option>Customer Satisfaction Survey</option>
            <option>Net Promoter Score (NPS)</option>
            <option>Product Feedback</option>
          </select>
        </div>
        <motion.button 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="button" 
          className="bg-gradient-to-r from-green-600 to-green-800 text-white px-6 py-2 rounded-full hover:from-green-700 hover:to-green-900 transition-all duration-300 shadow-lg"
        >
          Send Survey
        </motion.button>
      </form>
    </div>
  );

  const renderLoyaltyProgram = () => (
    <div>
      <p className="mb-4 text-gray-300">Set up a loyalty program for high-value customers:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-300">Reward Type</label>
          <select className="w-full bg-gray-700 border-gray-600 text-white rounded-md p-2">
            <option>Points</option>
            <option>Discounts</option>
            <option>Exclusive Offers</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-300">Target Spend</label>
          <input type="number" className="w-full bg-gray-700 border-gray-600 text-white rounded-md p-2" placeholder="Enter amount" />
        </div>
        <motion.button 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="button" 
          className="bg-gradient-to-r from-yellow-600 to-yellow-800 text-white px-6 py-2 rounded-full hover:from-yellow-700 hover:to-yellow-900 transition-all duration-300 shadow-lg"
        >
          Set Up Program
        </motion.button>
      </form>
    </div>
  );

  const CardWrapper = ({ children, className }) => (
    <motion.div 
      className={`p-6 rounded-xl shadow-lg ${className}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.02 }}
    >
      {children}
    </motion.div>
  );

  const ActionButton = ({ onClick, className, children }) => (
    <motion.button
      whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgba(255,255,255,0.5)" }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className={`px-4 py-2 rounded-full transition-all duration-300 shadow-lg ${className}`}
    >
      {children} <FaArrowCircleRight className="inline ml-1" />
    </motion.button>
  );

  return (
    <Layout>
      <div className="p-6 bg-gray-900 rounded-lg shadow-xl">
        <motion.h2 
          className="text-3xl font-bold text-white mb-6 flex items-center"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <FaUsers className="text-blue-500 mr-3" /> Customer Insights
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <CardWrapper className="bg-gradient-to-br from-green-500 to-green-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaSmile className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-green-200">Customer Satisfaction</p>
                  <p className="text-3xl font-bold text-white">{customerSatisfaction}%</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Initiate Feedback Loop', renderFeedbackLoop())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-green-500 hover:from-green-600 hover:to-green-700"
              >
                Gather Feedback
              </ActionButton>
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gradient-to-br from-red-500 to-red-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaSyncAlt className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-red-200">Churn Rate</p>
                  <p className="text-3xl font-bold text-white">{churnRate}%</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Launch Retention Campaign', renderRetentionCampaign())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-red-500 hover:from-red-600 hover:to-red-700"
              >
                Reduce Churn
              </ActionButton>
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gradient-to-br from-yellow-500 to-yellow-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaUsers className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-yellow-200">Lifetime Value</p>
                  <p className="text-3xl font-bold text-white">${lifetimeValue}</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Explore Loyalty Programs', renderLoyaltyProgram())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-yellow-500 hover:from-yellow-600 hover:to-yellow-700"
              >
                Increase Value
              </ActionButton>
            </div>
          </CardWrapper>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <CardWrapper className="bg-gray-800">
            <h3 className="text-2xl font-semibold text-white mb-4">Satisfaction Distribution</h3>
            <div style={{ height: '300px' }}>
              <Doughnut 
                data={satisfactionData} 
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: { 
                      labels: { color: 'white' },
                      position: 'bottom'
                    }
                  }
                }} 
              />
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gray-800">
            <h3 className="text-2xl font-semibold text-white mb-4">Churn Rate by Age Group</h3>
            <div style={{ height: '300px' }}>
              <Bar 
                data={churnData} 
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: { ticks: { color: 'white' } },
                    y: { ticks: { color: 'white' } }
                  },
                  plugins: {
                    legend: { 
                      labels: { color: 'white' },
                      position: 'bottom'
                    }
                  }
                }} 
              />
            </div>
          </CardWrapper>
        </div>

        <AnimatePresence>
          {showModal && (
            <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
              {modalContent.content}
            </Modal>
          )}
        </AnimatePresence>
      </div>
    </Layout>
  );
};

export default CustomerInsights;