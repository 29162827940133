import React, { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBullhorn, FaArrowCircleRight, FaChartLine, FaRocket } from 'react-icons/fa';
import Layout from './Layout';
import Modal from './Modal';

const MarketingCampaigns = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const campaignPerformance = {
    labels: ['Campaign A', 'Campaign B', 'Campaign C', 'Campaign D', 'Campaign E'],
    datasets: [
      {
        label: 'Conversion Rate (%)',
        data: [3.5, 4.2, 5.1, 2.9, 6.3],
        backgroundColor: [
          'rgba(75, 192, 192, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(153, 102, 255, 0.8)',
        ],
      },
    ],
  };

  const roiData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'ROI (%)',
        data: [120, 135, 150, 130, 160, 175],
        fill: false,
        borderColor: '#FF6384',
        tension: 0.1,
      },
    ],
  };

  const renderLaunchCampaign = () => (
    <div className="text-black">
      <p className="mb-4">Launch a new marketing campaign:</p>
      <form>
        <div className="mb-4">
          <label className="block">Campaign Name</label>
          <input type="text" className="w-full bg-gray-300 border-gray-600 text-black rounded-md p-2" placeholder="Enter campaign name" />
        </div>
        <div className="mb-4">
          <label className="block">Target Audience</label>
          <select className="w-full bg-gray-300 border-gray-600 text-black rounded-md p-2">
            <option>18-25</option>
            <option>26-35</option>
            <option>36-45</option>
            <option>45+</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block">Budget ($)</label>
          <input type="number" className="w-full bg-gray-300 border-gray-600 text-black rounded-md p-2" placeholder="Enter budget" />
        </div>
        <motion.button 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="submit"
          className="bg-gradient-to-r from-blue-600 to-blue-800 text-white px-6 py-2 rounded-full hover:from-blue-700 hover:to-blue-900 transition-all duration-300 shadow-lg"
        >
          Launch Campaign
        </motion.button>
      </form>
    </div>
  );

  const renderOptimizeCampaign = () => (
    <div className="text-black">
      <p className="mb-4">Optimize an existing campaign to improve performance:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Adjust the target audience for better engagement.</li>
        <li>Reallocate budget towards higher-performing channels.</li>
        <li>Refine the messaging to increase conversion rates.</li>
      </ul>
      <motion.button 
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        type="button"
        className="bg-gradient-to-r from-green-600 to-green-800 text-white px-6 py-2 rounded-full hover:from-green-700 hover:to-green-900 transition-all duration-300 shadow-lg"
      >
        Optimize Campaign
      </motion.button>
    </div>
  );

  const CardWrapper = ({ children, className }) => (
    <motion.div 
      className={`p-6 rounded-xl shadow-lg ${className}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.02 }}
    >
      {children}
    </motion.div>
  );

  const ActionButton = ({ onClick, className, children }) => (
    <motion.button
      whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgba(255,255,255,0.5)" }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className={`px-4 py-2 rounded-full transition-all duration-300 shadow-lg ${className}`}
    >
      {children} <FaArrowCircleRight className="inline ml-1" />
    </motion.button>
  );

  return (
    <Layout>
      <div className="p-6 bg-gray-900 rounded-lg shadow-xl">
        <motion.h2 
          className="text-3xl font-bold text-white mb-6 flex items-center"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <FaBullhorn className="text-purple-500 mr-3" /> Marketing Campaigns
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <CardWrapper className="bg-gradient-to-br from-yellow-500 to-yellow-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaChartLine className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-yellow-200">ROI Over Time</p>
                  <p className="text-3xl font-bold text-white">{roiData.datasets[0].data.slice(-1)[0]}%</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Optimize Campaign', renderOptimizeCampaign())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-yellow-500 hover:from-yellow-600 hover:to-yellow-700"
              >
                Optimize Campaign
              </ActionButton>
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gradient-to-br from-green-500 to-green-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaRocket className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-green-200">Launch New Campaign</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Launch Campaign', renderLaunchCampaign())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-green-500 hover:from-green-600 hover:to-green-700"
              >
                Launch Campaign
              </ActionButton>
            </div>
          </CardWrapper>
        </div>

        <div className="grid grid-cols-1 gap-6 mb-8">
          <CardWrapper className="bg-gray-800">
            <h3 className="text-2xl font-semibold text-white mb-4 flex items-center">
              Conversion Rate by Campaign
            </h3>
            <div style={{ height: '400px' }}>
              <Bar 
                data={campaignPerformance} 
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: { ticks: { color: 'white' } },
                    y: { ticks: { color: 'white' } }
                  },
                  plugins: {
                    legend: { 
                      labels: { color: 'white' },
                      position: 'bottom'
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => `Conversion Rate: ${context.parsed.y}%`
                      }
                    }
                  }
                }} 
              />
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gray-800">
            <h3 className="text-2xl font-semibold text-white mb-4 flex items-center">
              ROI Over Time
            </h3>
            <div style={{ height: '400px' }}>
              <Line 
                data={roiData} 
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: { ticks: { color: 'white' } },
                    y: { ticks: { color: 'white' } }
                  },
                  plugins: {
                    legend: { 
                      labels: { color: 'white' },
                      position: 'bottom'
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => `ROI: ${context.parsed.y}%`
                      }
                    }
                  }
                }} 
              />
            </div>
          </CardWrapper>
        </div>

        <AnimatePresence>
          {showModal && (
            <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
              {modalContent.content}
            </Modal>
          )}
        </AnimatePresence>
      </div>
    </Layout>
  );
};

export default MarketingCampaigns;
