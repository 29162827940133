import React, { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTags, FaArrowCircleRight, FaMoneyBillWave, FaArrowUp, FaInfoCircle } from 'react-icons/fa';
import Layout from './Layout';
import Modal from './Modal';
import { Tooltip } from 'react-tooltip';

const PricingStrategies = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const pricingData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Revenue Impact',
        data: [32000, 35000, 40000, 43000, 47000, 50000],
        fill: false,
        borderColor: '#FF6384',
        tension: 0.1,
      },
    ],
  };

  const marginData = {
    labels: ['Smartphone Pro Max', 'Smartwatch X2', 'Laptop Pro 15', 'Wireless Earbuds', '4K TV Ultra'],
    datasets: [
      {
        label: 'Profit Margin (%)',
        data: [25, 30, 20, 35, 40],
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
        ],
      },
    ],
  };

  const averageSellingPrice = 499;
  const targetMargin = 35;

  const renderPriceSimulation = () => (
    <div>
      <p className="mb-4 text-gray-300">Adjust the price of products to see the potential impact on revenue and margin:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-300">Select Product</label>
          <select className="w-full bg-gray-700 border-gray-600 text-white rounded-md p-2">
            {marginData.labels.map((product, index) => (
              <option key={index} value={product}>{product}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-300">New Price ($)</label>
          <input type="number" className="w-full bg-gray-700 border-gray-600 text-white rounded-md p-2" placeholder="Enter new price" />
        </div>
        <motion.button 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="button"
          className="bg-gradient-to-r from-blue-600 to-blue-800 text-white px-6 py-2 rounded-full hover:from-blue-700 hover:to-blue-900 transition-all duration-300 shadow-lg"
        >
          Simulate Price Change
        </motion.button>
      </form>
    </div>
  );

  const renderMarginImprovement = () => (
    <div>
      <p className="mb-4 text-gray-300">Identify ways to improve profit margins for your products:</p>
      <ul className="list-disc list-inside mb-4 text-gray-300">
        <li>Negotiate better terms with suppliers to reduce costs.</li>
        <li>Optimize the pricing model to increase the selling price while maintaining customer satisfaction.</li>
        <li>Enhance product features to justify a higher price point.</li>
      </ul>
      <motion.button 
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        type="button"
        className="bg-gradient-to-r from-green-600 to-green-800 text-white px-6 py-2 rounded-full hover:from-green-700 hover:to-green-900 transition-all duration-300 shadow-lg"
      >
        Implement Strategy
      </motion.button>
    </div>
  );

  const CardWrapper = ({ children, className }) => (
    <motion.div 
      className={`p-6 rounded-xl shadow-lg ${className}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.02 }}
    >
      {children}
    </motion.div>
  );

  const ActionButton = ({ onClick, className, children }) => (
    <motion.button
      whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgba(255,255,255,0.5)" }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className={`px-4 py-2 rounded-full transition-all duration-300 shadow-lg ${className}`}
    >
      {children} <FaArrowCircleRight className="inline ml-1" />
    </motion.button>
  );

  return (
    <Layout>
      <div className="p-6 bg-gray-900 rounded-lg shadow-xl">
        <motion.h2 
          className="text-3xl font-bold text-white mb-6 flex items-center"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <FaTags className="text-blue-500 mr-3" /> Pricing Strategies
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <CardWrapper className="bg-gradient-to-br from-yellow-500 to-yellow-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaMoneyBillWave className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-yellow-200">Average Selling Price</p>
                  <p className="text-3xl font-bold text-white">${averageSellingPrice}</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Price Simulation', renderPriceSimulation())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-yellow-500 hover:from-yellow-600 hover:to-yellow-700"
              >
                Adjust Prices
              </ActionButton>
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gradient-to-br from-green-500 to-green-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaArrowUp className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-green-200">Target Profit Margin</p>
                  <p className="text-3xl font-bold text-white">{targetMargin}%</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Improve Margins', renderMarginImprovement())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-green-500 hover:from-green-600 hover:to-green-700"
              >
                Improve Margins
              </ActionButton>
            </div>
          </CardWrapper>
        </div>

        <div className="grid grid-cols-1 gap-6 mb-8">
          <CardWrapper className="bg-gray-800">
            <h3 className="text-2xl font-semibold text-white mb-4 flex items-center">
              Revenue Impact Over Time
              <FaInfoCircle className="ml-2 text-blue-400 cursor-pointer" data-tip="Shows how pricing changes affect revenue" />
            </h3>
            <div style={{ height: '400px' }}>
              <Line 
                data={pricingData} 
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: { ticks: { color: 'white' } },
                    y: { ticks: { color: 'white' } }
                  },
                  plugins: {
                    legend: { 
                      labels: { color: 'white' },
                      position: 'bottom'
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => `Revenue: $${context.parsed.y}`
                      }
                    }
                  }
                }} 
              />
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gray-800">
            <h3 className="text-2xl font-semibold text-white mb-4 flex items-center">
              Profit Margin by Product
              <FaInfoCircle className="ml-2 text-blue-400 cursor-pointer" data-tip="Click on a bar for more details" />
            </h3>
            <div style={{ height: '400px' }}>
              <Bar 
                data={marginData} 
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: { ticks: { color: 'white' } },
                    y: { ticks: { color: 'white' } }
                  },
                  plugins: {
                    legend: { 
                      labels: { color: 'white' },
                      position: 'bottom'
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => `Margin: ${context.parsed.y}%`
                      }
                    }
                  },
                  onClick: (event, elements) => {
                    if (elements.length > 0) {
                      const index = elements[0].index;
                      handleModalOpen('Product Margin Details', renderProductMarginDetails(marginData.labels[index], marginData.datasets[0].data[index]));
                    }
                  }
                }} 
              />
            </div>
          </CardWrapper>
        </div>

        <AnimatePresence>
          {showModal && (
            <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
              {modalContent.content}
            </Modal>
          )}
        </AnimatePresence>

        <Tooltip id="chart-tooltip" />
      </div>
    </Layout>
  );
};

const renderProductMarginDetails = (product, margin) => (
  <div className="text-gray-300">
    <h3 className="text-xl font-bold mb-4">{product}</h3>
    <p className="mb-2">Current Profit Margin: {margin}%</p>
    <p className="mb-4">Last Price Adjustment: May 15, 2023</p>
    <h4 className="text-lg font-semibold mb-2">Margin Improvement Suggestion</h4>
    <p>Consider increasing the price by 5% or negotiating a 3% reduction in supply costs to reach the target margin of 35%.</p>
  </div>
);

export default PricingStrategies;