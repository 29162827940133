import React, { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBoxes, FaArrowCircleRight, FaTruckLoading, FaRedoAlt, FaInfoCircle } from 'react-icons/fa';
import Layout from './Layout';
import Modal from './Modal';
import { Tooltip } from 'react-tooltip';

const InventoryManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const stockLevels = {
    labels: ['Smartphone Pro Max', 'Smartwatch X2', 'Laptop Pro 15', 'Wireless Earbuds', '4K TV Ultra'],
    datasets: [
      {
        label: 'Stock Level (Units)',
        data: [150, 200, 80, 300, 50],
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
        ],
      },
    ],
  };

  const turnoverData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Inventory Turnover Ratio',
        data: [5.2, 5.5, 6.0, 5.8, 6.2, 6.5],
        fill: false,
        borderColor: '#4CAF50',
        tension: 0.1,
      },
    ],
  };

  const stockoutRate = 8;

  const renderReorderStock = () => (
    <div>
      <p className="mb-4 text-gray-300">Reorder stock for low inventory items:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-300">Select Product</label>
          <select className="w-full bg-gray-700 border-gray-600 text-white rounded-md p-2">
            {stockLevels.labels.map((product, index) => (
              <option key={index} value={product}>{product}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-300">Order Quantity</label>
          <input type="number" className="w-full bg-gray-700 border-gray-600 text-white rounded-md p-2" placeholder="Enter quantity" />
        </div>
        <motion.button 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="button"
          className="bg-gradient-to-r from-blue-600 to-blue-800 text-white px-6 py-2 rounded-full hover:from-blue-700 hover:to-blue-900 transition-all duration-300 shadow-lg"
        >
          Reorder Stock
        </motion.button>
      </form>
    </div>
  );

  const renderAdjustInventory = () => (
    <div>
      <p className="mb-4 text-gray-300">Adjust inventory strategies to improve turnover and reduce stockouts:</p>
      <ul className="list-disc list-inside mb-4 text-gray-300">
        <li>Implement a Just-In-Time (JIT) inventory system to reduce holding costs.</li>
        <li>Increase order frequency for fast-moving products.</li>
        <li>Conduct regular inventory audits to identify discrepancies.</li>
      </ul>
      <motion.button 
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        type="button"
        className="bg-gradient-to-r from-green-600 to-green-800 text-white px-6 py-2 rounded-full hover:from-green-700 hover:to-green-900 transition-all duration-300 shadow-lg"
      >
        Implement Strategy
      </motion.button>
    </div>
  );

  const CardWrapper = ({ children, className }) => (
    <motion.div 
      className={`p-6 rounded-xl shadow-lg ${className}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.02 }}
    >
      {children}
    </motion.div>
  );

  const ActionButton = ({ onClick, className, children }) => (
    <motion.button
      whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgba(255,255,255,0.5)" }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className={`px-4 py-2 rounded-full transition-all duration-300 shadow-lg ${className}`}
    >
      {children} <FaArrowCircleRight className="inline ml-1" />
    </motion.button>
  );

  return (
    <Layout>
      <div className="p-6 bg-gray-900 rounded-lg shadow-xl">
        <motion.h2 
          className="text-3xl font-bold text-white mb-6 flex items-center"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <FaBoxes className="text-green-500 mr-3" /> Inventory Management
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <CardWrapper className="bg-gradient-to-br from-yellow-500 to-yellow-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaTruckLoading className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-yellow-200">Stockout Rate</p>
                  <p className="text-3xl font-bold text-white">{stockoutRate}%</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Reorder Stock', renderReorderStock())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-yellow-500 hover:from-yellow-600 hover:to-yellow-700"
              >
                Reorder Stock
              </ActionButton>
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gradient-to-br from-green-500 to-green-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FaRedoAlt className="text-white text-4xl mr-4" />
                <div>
                  <p className="text-lg font-semibold text-green-200">Inventory Turnover</p>
                  <p className="text-3xl font-bold text-white">{turnoverData.datasets[0].data.slice(-1)[0]}</p>
                </div>
              </div>
              <ActionButton
                onClick={() => handleModalOpen('Adjust Inventory Strategies', renderAdjustInventory())}
                className="bg-gradient-to-r from-gray-800 to-gray-900 text-white border border-green-500 hover:from-green-600 hover:to-green-700"
              >
                Adjust Strategy
              </ActionButton>
            </div>
          </CardWrapper>
        </div>

        <div className="grid grid-cols-1 gap-6 mb-8">
          <CardWrapper className="bg-gray-800">
            <h3 className="text-2xl font-semibold text-white mb-4 flex items-center">
              Stock Levels by Product
              <FaInfoCircle className="ml-2 text-blue-400 cursor-pointer" data-tip="Click on a bar for more details" />
            </h3>
            <div style={{ height: '400px' }}>
              <Bar 
                data={stockLevels} 
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: { ticks: { color: 'white' } },
                    y: { ticks: { color: 'white' } }
                  },
                  plugins: {
                    legend: { 
                      labels: { color: 'white' },
                      position: 'bottom'
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => `Stock: ${context.parsed.y} units`
                      }
                    }
                  },
                  onClick: (event, elements) => {
                    if (elements.length > 0) {
                      const index = elements[0].index;
                      handleModalOpen('Product Details', renderProductDetails(stockLevels.labels[index], stockLevels.datasets[0].data[index]));
                    }
                  }
                }} 
              />
            </div>
          </CardWrapper>

          <CardWrapper className="bg-gray-800">
            <h3 className="text-2xl font-semibold text-white mb-4">Inventory Turnover Over Time</h3>
            <div style={{ height: '400px' }}>
              <Line 
                data={turnoverData} 
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: { ticks: { color: 'white' } },
                    y: { ticks: { color: 'white' } }
                  },
                  plugins: {
                    legend: { 
                      labels: { color: 'white' },
                      position: 'bottom'
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => `Turnover: ${context.parsed.y}`
                      }
                    }
                  }
                }} 
              />
            </div>
          </CardWrapper>
        </div>

        <AnimatePresence>
          {showModal && (
            <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
              {modalContent.content}
            </Modal>
          )}
        </AnimatePresence>

        <Tooltip id="chart-tooltip" />
      </div>
    </Layout>
  );
};

const renderProductDetails = (product, stock) => (
  <div className="text-gray-300">
    <h3 className="text-xl font-bold mb-4">{product}</h3>
    <p className="mb-2">Current Stock: {stock} units</p>
    <p className="mb-4">Last Reorder Date: June 15, 2023</p>
    <h4 className="text-lg font-semibold mb-2">Reorder Recommendation</h4>
    <p>Based on current sales trends, we recommend reordering {Math.max(100 - stock, 0)} units to maintain optimal stock levels.</p>
  </div>
);

export default InventoryManagement;